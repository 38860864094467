:host {
  font-family: 'Arial', sans-serif;
  background: var(--nylas-color-primary-50); /* Closest to #f0f0f0 */
  color: var(--nylas-color-primary-800); /* Closest to #333 */
  margin: 0;
  display: contents;
}

input {
  box-sizing: border-box;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-box {
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: var(--nylas-border-radius-2x);
  background: var(--nylas-color-primary-50); /* Assuming white background */
  text-align: center;
}

.login-box h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.login-box p {
  margin-bottom: 1em;
}

.login-methods button {
  display: block;
  width: 100%;
  padding: 10px 10px 10px 50px;
  margin-bottom: 10px;
  border: none;
  border-radius: var(--nylas-border-radius);
  border: 1px solid var(--nylas-color-primary-300); /* Closest to #b1b1b1 */
  background: var(--nylas-color-primary-100); /* Closest to #f1f1f1 */
  color: var(--nylas-color-primary-600); /* Closest to #5f6368 */
  cursor: pointer;
  transition: background 0.3s ease;
  position: relative;
  text-align: left;
}

.login-methods button svg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.login-methods button:hover {
  background: var(--nylas-color-primary-200); /* Closest to #e2e2e2 */
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--nylas-color-primary-200); /* Closest to #ddd */
  border-radius: var(--nylas-border-radius);
  background: var(--nylas-color-primary-100); /* Closest to #f1f1f1 */
  color: var(--nylas-color-primary-800); /* Closest to #333 */
}

.login-form button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: var(--nylas-border-radius);
  background: var(--nylas-color-primary-500); /* Closest to #007bff */
  color: var(--nylas-color-primary-50); /* Assuming white text */
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-form button:hover {
  background: var(--nylas-color-primary-600); /* Closest to #0056b3 */
}
