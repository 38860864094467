import { RegisterComponent } from '@/common/register-component';
import { NylasAuthConnectorInterface } from '@/connector/connector-interface';
import { Component, Event, EventEmitter, Host, Prop, State, h } from '@stencil/core';
import { ObservableMap } from '@stencil/store';
import { NylasAuthStoreState } from '@/stores/auth-store';

@Component({
  tag: 'nylas-login',
  styleUrl: 'nylas-login.css',
  shadow: true,
})
export class NylasLogin {
  @Prop() readonly isAuthenticated: boolean = false;
  @Prop() readonly nylasConnector?: NylasAuthConnectorInterface;
  @Prop() readonly scopes?: string[];
  @Prop() readonly loginMethod: 'redirect' | 'emebded' = 'redirect';
  @Prop() readonly companyName: string = 'Nylas';
  @Prop() readonly logoUrl: string = 'https://www.nylas.com/wp-content/uploads/main-logo.svg';
  @Prop() readonly logoWidth?: string = 'min-content';
  @Prop() readonly providers?: string = 'google,microsoft';
  @Prop() readonly buttonText: string = 'Login';

  @Event() buttonClick!: EventEmitter<{ url?: string }>;

  @State() email: string = '';

  connectedCallback() {}

  disconnectedCallback() {}

  componentWillLoad() {}

  async componentDidLoad() {
    const exchangedToken = await this.nylasConnector?.auth.manageHostedAuthCodeExchange(window.location.href);
    if (exchangedToken) {
      // Remove the code query param from the user's browser url
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }

  private _handleLoginRedirect = async () => {
    const url = await this.nylasConnector?.auth.getHostedAuthRedirectURL(this.scopes);

    const event = this.buttonClick.emit({ url });
    if (event.defaultPrevented) {
      return;
    }

    if (url) {
      window.location.href = url;
    }
  };

  private _onGoogleButtonClick = async (event: Event) => {
    // Prevent the form from submitting
    event.preventDefault();

    const url = await this.nylasConnector?.auth.getHostedAuthRedirectURL(this.scopes, undefined, 'google');
    if (url) {
      window.location.href = url;
    }
  };

  private _onMicrosoftButtonClick = async (event: Event) => {
    // Prevent the form from submitting
    event.preventDefault();

    const url = await this.nylasConnector?.auth.getHostedAuthRedirectURL(this.scopes, undefined, 'microsoft');
    if (url) {
      window.location.href = url;
    }
  };

  private _onFormSubmit = async (event: Event) => {
    // Prevent the form from submitting
    event.preventDefault();

    const url = await this.nylasConnector?.auth.getHostedAuthRedirectURL(this.scopes, this.email);
    if (url) {
      window.location.href = url;
    }
  };

  @RegisterComponent<
    NylasLogin,
    NylasAuthConnectorInterface,
    {
      auth: ObservableMap<NylasAuthStoreState>;
    }
  >({
    name: 'nylas-login',
    connectorToProp: 'nylasConnector',
    stateToProps: new Map([['auth.isAuthenticated', 'isAuthenticated']]),
    fireRegisterEvent: true,
  })
  render() {
    const splitProviders = this.providers?.split(',') || [];
    return (
      <Host>
        {this.loginMethod === 'redirect' && (
          <div class="redirect-container">
            {!this.isAuthenticated && (
              <button part="button" onClick={this._handleLoginRedirect}>
                {this.buttonText}
              </button>
            )}
          </div>
        )}
        {this.loginMethod === 'emebded' && (
          <main class="login-container">
            <section class="login-box">
              <header>
                <img
                  src={this.logoUrl}
                  alt={`${this.companyName} logo`}
                  width={'100%'}
                  style={{
                    minWidth: this.logoWidth,
                  }}
                />
                <h1>Welcome</h1>
              </header>
              <p>Log in to your account to continue to {this.companyName}</p>
              <div class="login-methods">
                {splitProviders.includes('google') && (
                  <button class="login-google" onClick={this._onGoogleButtonClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 262">
                      <path fill="#4285F4" d="M255.6 131.6c0-8.2-.8-16.2-2.2-23.9H130v45.3h70.3c-3 16.1-12.4 29.7-26.3 38.9v32.4h42.6c25-23 39.4-56.7 39.4-97.7z" />
                      <path
                        fill="#34A853"
                        d="M130 262c35.3 0 64.9-11.7 86.5-31.7l-42.6-32.4c-11.7 7.9-26.7 12.6-43.9 12.6-33.7 0-62.3-22.7-72.5-53.4H14.5v33.3A130.3 130.3 0 00130 262z"
                      />
                      <path fill="#FBBC05" d="M57.5 158.1a72.7 72.7 0 010-54.2V70.6H14.5a130.4 130.4 0 000 120.8l43-33.3z" />
                      <path fill="#EA4335" d="M130 51.6c19 0 36 6.5 49.4 19.2l37-36C195 13.1 165.2 0 130 0A130.3 130.3 0 0014.5 70.6l43 33.3c10.2-30.7 38.8-53.3 72.5-53.3z" />
                    </svg>
                    Continue with Google
                  </button>
                )}
                {splitProviders.includes('microsoft') && (
                  <button class="login-microsoft" onClick={this._onMicrosoftButtonClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512">
                      <path fill="#F3F3F3" d="M0 32v192h192V32H0zm256 0v192h192V32H256zm192 256v192h-192V288h192zM0 224v192h192V224H0z" />
                    </svg>
                    Continue with Microsoft
                  </button>
                )}
                {/* <button class="login-all">View all providers</button> */}
              </div>
              <form class="login-form" method="POST" onSubmit={this._onFormSubmit}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required value={this.email} />
                <button type="submit">Next</button>
              </form>
            </section>
          </main>
        )}
      </Host>
    );
  }
}
